import React, { Suspense } from "react";
// import { Provider } from "react-redux";
// import reducer from "./store/reducer";
// import { createStore } from "redux";
import axios from "axios";
import Loader from "./Components/Loader/Loader";
import { _axiosIntercept, _getOrSetIpLocation, _getUserData, _parseJwt, _removeURLParam } from "./util/commonFunctions";
import "./App.css";
// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// const store = createStore(reducer,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
const Router = React.lazy(() => import("./Router/Router"));

export default class App extends React.Component {
	state = {
		loginCheck: false,
		loggedIn: false,
		postMessage: "test",
	};

	loginOnToken = () => {
        let urlParams = new URLSearchParams(this?.props?.location?.search);
        if (urlParams.has("token")) {
            window.localStorage.setItem("token", urlParams.get("token"));
            let newUrl = _removeURLParam("token", window?.location?.href);
            window.history.replaceState({}, null, newUrl);
        }
    };

	componentDidMount() {
		this.loginOnToken();
		_getOrSetIpLocation(true);
		_axiosIntercept(axios);
	}

	render() {
		return (
			<React.Fragment>
				{/* <Provider store={store}> */}
				<Suspense fallback={<Loader />}>
					<Router />
				</Suspense>
				{/* </Provider> */}
			</React.Fragment>
		);
	}
}
