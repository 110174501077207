import 'react-app-polyfill/stable';
import "core-js/stable";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getPerformance } from 'firebase/performance';
import { BrowserRouter, Route } from "react-router-dom";
import App from "./App";

if(!!window && !!window.location && !!window.location.href && !(window.location.href.includes("localhost") || window.location.href.includes("dev.geniemode") || window.location.href.includes("qa.geniemode"))) {
	Sentry.init({
		dsn: process.env.REACT_APP_PROD_FE_GENIE_SENTRY,
		integrations: [new Integrations.BrowserTracing()],
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
	});
}

// reportWebVitals(console.log);
window.addEventListener('load', () => {
	let service_worker_url = "/service_worker.js";
	if(service_worker_url) {
		navigator && navigator.serviceWorker && navigator.serviceWorker.register && (typeof(navigator.serviceWorker.register) === 'function') && navigator.serviceWorker.register(service_worker_url)
		.then(registration => {
			console.log("geniemode service worker",registration);
		})
		.catch(error => {
			console.log(error);
		});
	}

	// webengage
	var webengage; (function (w, e, b, n, g) { function o(e, t) { e[t[t.length - 1]] = function () { r.__queue.push([t.join("."), arguments]); }; } var i, s, r = w[b], z = " ", l = "init options track screen onReady".split(z), a = "feedback survey notification".split(z), c = "options render clear abort".split(z), p = "Open Close Submit Complete View Click".split(z), u = "identify login logout setAttribute".split(z); if (!r || !r.__v) { for ( w[b] = r = { __queue: [], __v: "6.0", user: {} }, i = 0; i < l.length; i++ ) o(r, [l[i]]); for (i = 0; i < a.length; i++) { for (r[a[i]] = {}, s = 0; s < c.length; s++){o(r[a[i]], [a[i], c[s]])}; for (s = 0; s < p.length; s++){o(r[a[i]], [a[i], "on" + p[s]])}; } for (i = 0; i < u.length; i++){o(r.user, ["user", u[i]])}; setTimeout(function () { var f = e.createElement("script"), d = e.getElementById("_webengage_script_tag"); f.type = "text/javascript"; f.async = !0; f.src = ("https:" == e.location.protocol ? "https://ssl.widgets.webengage.com" : "http://cdn.widgets.webengage.com") + "/js/webengage-min-v-6.0.js"; d.parentNode.insertBefore(f, d); }); } })(window, document, "webengage"); if(window.location.href.includes("dev.geniemode.com")){ window.webengage.init(process.env.REACT_APP_DEV_FE_GENIE_WEBENGAGE); }else if(window.location.href.includes("https://geniemode.com") || window.location.href.includes("www.geniemode.com")){ window.webengage.init(process.env.REACT_APP_PROD_FE_GENIE_WEBENGAGE); } else if(window.location.href.includes("qa.geniemode.com")){ window.webengage.init(process.env.REACT_APP_QA_FE_GENIE_WEBENGAGE); }

	// Firebase
	if (!window.location.href.includes("//localhost:") && !window.location.href.includes("dev.geniemode") && !window.location.href.includes("192.168") && !window.location.href.includes("qa.geniemode")) {
		// Your web app's Firebase configuration
		// For Firebase JS SDK v7.20.0 and later, measurementId is optional
		const firebaseConfig = {
			apiKey: process.env.REACT_APP_PROD_FE_GENIE_FIREBASE,
			authDomain: "geniemodecom.firebaseapp.com",
			projectId: "geniemodecom",
			storageBucket: "geniemodecom.appspot.com",
			messagingSenderId: process.env.REACT_APP_PROD_FE_GENIE_FIREBASE_MESSAGE_SENDER_ID,
			appId: process.env.REACT_APP_PROD_FE_GENIE_FIREBASE_APP_ID,
			measurementId: process.env.REACT_APP_PROD_FE_GENIE_GTAG_ID,
		};
		const app = initializeApp(firebaseConfig);
		const analytics = getAnalytics(app);
		const perf = getPerformance(app);
	}

});


document.addEventListener("DOMContentLoaded", e => {
	ReactDOM.render(
        <BrowserRouter>
            <Route path="/" component={App} />
        </BrowserRouter>,
        document.getElementById("main"),
    );
});

window.addEventListener("beforeinstallprompt", function(event) {
	event.preventDefault();
});
